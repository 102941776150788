
import http from "@/http";
import { Company } from "@/interfaces";
import Vue from "vue";

import { mdiPlus, mdiMagnify } from "@mdi/js";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["companies"]),
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "業者",
          disabled: true,
          to: "/companies",
        },
      ];
    },
    icon() {
      return {
        mdiPlus,
        mdiMagnify,
      };
    },
  },
  methods: {
    async click(company: Company) {
      const { data } = await http.get(`users/${company.user_id}`);

      window.location.href = data.login_link;
    },
  },
});
